/*--------------------------------------------------------------
# Review
--------------------------------------------------------------*/

.single-review {
    border-radius: 5px;
    padding: 10px 15px;
    .quote-wrap {
        text-align: center;
        padding: 50px 20px 35px;
        border: 2px solid #eeeeee;
        @include transition();
        p {
			font-weight: 500;
			font-style: italic;
			margin-bottom: 0px;
			color: $text-color;
        }
        .star {
            margin-top: 20px;
            .checked {
                color: orange;
            }
        }
    }
    .userinfo-wrap {
        padding: 20px;
        @include transition();
        img {
            margin-right: 20px;
            width: auto !important;
        }
        h4 {
			margin-bottom: 2px;
			font-size: 16px;
			color: $title-color;
			font-weight: 600;
		}
		p {
			margin-bottom: 0px;
			color: $text-color;
			font-size: 14px;
		}
    }
    &:hover {
		cursor: pointer;
		.quote-wrap {
			border: 2px solid $primary-color;
		}
    }
}

.client-review-section {
    .owl-carousel .owl-item img {
        width: auto !important;
        @media(max-width: 991px) {
            opacity: .1;
        }
    }
}

.single-review-2 {
    .desc {
        position: relative;
        .review-bg {
            position: absolute;
            left: 270px;
            bottom: 0;
            @media(max-width: 991px) {
                display: none;
            }
        }
        .review-bg2 {
            padding-left: 110px;
            @media(max-width: 991px) {
                padding-left: 0;
            }
        }
        .content {
            position: absolute;
            left: 512px;
            bottom: 42px;
            max-width: 460px;
            @media(max-width: 991px) {
                width: 100%;
                left: 100%;
                padding: 0 22px;
                opacity: 1;
                color: #222;
            }
        }
        h4 {
            margin-top: 40px;
        }
        p {
            margin-bottom: 0px;
        }
        @media(max-width:991px) {
            text-align: center;
        }
    }
    @media(max-width:1199px) {
        .thumb {
            margin-bottom: 30px;
            text-align: center !important;
            img {
                margin-right: auto;
                margin-left: auto;
                display: inline-block;
            }
        }
    }
}