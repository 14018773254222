
//    Font Family

$primary-font	: 'Montserrat', sans-serif;

// --------------------------- Color variations ----------------------

$primary-color	: #d10373;  
// $primary-color	: #ff7500;  //#

$primary-color2	: #d13691;

$title-color	: #1e283c;
$text-color		: #50668f;
$aquablue-color : #edf6ff;

$white			: #fff;
$offwhite		: #f2f5fa;
$black			: #222;
